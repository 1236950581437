.slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meuCarrossel {
    height: max-content;
    max-width: 100%;
    overflow: hidden; 
  }

  .meuCarrossel div img {
    max-width: 70%;
    width: 50%;
  }

  .slick-slide > div > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  /* @media screen and (min-width: 480px){
    .meuCarrossel div img {
      max-width: 50%;
      width: 90%;
    }
  }

  @media screen and (min-width: 768px){
    .meuCarrossel div img {
      max-width: 45%;
      width: 90%;
    }
  }

  @media screen and (min-width: 1600px){
    .meuCarrossel div img {
      max-width: 40%;
      width: 90%;
    }
  } */
@media screen and (max-width: 1024px){
    .meuCarrossel div img {
      max-width: 60%;
      width: 100%;
    }
  }

  @media screen and (max-width: 900px){
    .meuCarrossel div img {
      max-width: 50%;
      width: 100%;
    }
  }
  
@media screen and (max-width: 768px){
    .meuCarrossel div img {
      max-width: 50%;
      width: 100%;
    }
  }

@media screen and (max-width: 480px){
    .meuCarrossel div img {
      max-width: 85%;
      width: 100%;
    }
  }

@media screen and (min-width: 1600px){
    .meuCarrossel div img {
      max-width: 30%;
      width: 100%;
    }
  }