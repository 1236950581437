@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.app {
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.active {
  color: #54FF54;
}

.active::after {
  content: "";
  height: 2px;
  background: #54FF54;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: underline .3s linear forwards;
}

@keyframes underline {
  from {width: 0;}
  to {width: 100%;}
}